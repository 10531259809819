import staticArticles from './allStaticArticles.preval';

const ARTICLE_ORDER_CATEGORY = ['health', 'finance', 'education', 'legal', 'coronavirus', 'benefits'];
const ARTICLE_ORDER_PRIORITY = { High: 1, Medium: 2, Low: 3 };

export function allArticles() {
  return staticArticles;
}

function getPriorityVal(priority) {
  const value = ARTICLE_ORDER_PRIORITY[priority];

  return value ? value : 4;
}

export function prioritizeArticles(articles) {
  if (!articles) return;

  return articles?.sort((a, b) => {
    return getPriorityVal(a.article_priority) - getPriorityVal(b.article_priority);
  });
}

export function filterArticlesCategory(articles) {
  const filteredArticles = [];
  const seenCategories = {};

  for (const obj of articles) {
    const category = obj.article_category;
    if (!seenCategories[category]) {
      seenCategories[category] = true;
      filteredArticles.push(obj);
    }
  }

  return filteredArticles;
}

export function sortArticlesCategory(articles) {
  return articles.sort(
    (a, b) => ARTICLE_ORDER_CATEGORY.indexOf(a.article_category) - ARTICLE_ORDER_CATEGORY.indexOf(b.article_category)
  );
}
