import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Actions from '@/components/cards/ArticleCard/Actions';
import ContentstackImage from '@/components/ContentstackImage';
import { Lock } from '@/icons';
import { articleType } from '@/types';
import { articleImage } from '@/utilities/articles/articleCard';
import { useTranslation } from '@separate/hooks/translation';
import { formatLocalDateSimple } from '@separate/utilities/datetime';
import styles from './articleListItemDesktop.module.scss';

const ArticleListItemDesktop = ({ article, className, userIsPremium, isLoading, isLoggedIn }) => {
  const { t } = useTranslation();

  const premiumUserAndArticle = userIsPremium && article.is_premium;
  const loadingUserAndArticleIsPremium = isLoading && article.is_premium;
  const isLocked = !userIsPremium && article.is_premium;
  const linkToArticle = isLocked ? '#/' : `/articles/${article.article_category}${article.url}`;

  const [showMessage, setShowMessage] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);

  const handleCardClick = () => {
    if (!isLocked) return;

    setShowMessage(true);
  };

  const handleLogin = () => {
    setShowMessage(false);
    setShowLogin(true);
  };

  return (
    <>
      <Link href={linkToArticle}>
        <a className='text-decoration-none h-100' onClick={handleCardClick}>
          <div className={cx(className, 'd-flex justify-content-between')}>
            <div className={styles.leftColumn}>
              <span>{t(`articles.categories.${article.article_category}`)}</span>
              <small>{t("articles.published")} {formatLocalDateSimple(article?.publish_details?.time)}</small>
              <p>{article.title}</p>
              <div className={cx(styles.headerDivider, 'mt-1')} />
              <p className={styles.articleDescription}>{article.article_description}</p>
            </div>

            <div className={styles.imageWrapper}>
              <ContentstackImage
                src={articleImage(article)}
                width={500}
              />
              {loadingUserAndArticleIsPremium || isLocked ? (
                <div className={cx(styles.lock, "d-flex flex-column justify-content-center align-items-center")}>
                  <Lock className="mb-1" />
                  SEP+
                </div>
              ) : premiumUserAndArticle && (
                <div className={cx(styles.premiumUserAndArticle, "d-flex flex-column justify-content-center align-items-center")}>
                  SEP+
                </div>
              )}
            </div>
          </div>
        </a>
      </Link>
      {isLocked && (
        <Actions
          showMessage={showMessage}
          showLogin={showLogin}
          dismissMessage={() => setShowMessage(false)}
          dismissLogin={() => setShowLogin(false)}
          handleLogin={handleLogin}
          isLoggedIn={isLoggedIn}
        />
      )}
    </>
  );
};

export default ArticleListItemDesktop;

ArticleListItemDesktop.propTypes = {
  article: articleType,
  className: PropTypes.string,
  userIsPremium: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};
