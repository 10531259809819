// Docs https://www.contentstack.com/docs/developers/apis/image-delivery-api/
// https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images

const MAX_WIDTH = 960;

export function optimizedImage(url, params = {}) {
  let devicePixelRatio, screenWidth;
  if (process.client) {
    devicePixelRatio = params.devicePixelRatio || window.devicePixelRatio || 1;
    screenWidth =
      params.screenWidth ||
      Math.round(
        Math.min(
          window.screen && (window.screen.availWidth || window.screen.width),
          MAX_WIDTH
        ) * devicePixelRatio
      );
  } else {
    devicePixelRatio = params.devicePixelRatio || 1;
    screenWidth = params.screenWidth || MAX_WIDTH;
  }
  delete params.devicePixelRatio;
  delete params.screenWidth;
  if (params.width) {
    params.width = Math.min(screenWidth, Math.round(params.width * devicePixelRatio));
  }
  if (params.height) {
    params.height = Math.round(params.height * devicePixelRatio);
  }

  if (params.fit === "crop" && params.width && params.height) {
    delete params.fit;
    params.crop = `${params.width}:${params.height},smart`;
  }

  let query = new URLSearchParams({
    auto: "webp",
    format: "jpg",
    quality: 80,
    disable: "upscale",
    ...params,
  }).toString();

  return `${url}?${query}`;
}

/**
 * sizes = { media_query: [width, height] }
 */
export function optimizedSrcset(url, sizes) {
  const srcset = {};
  const sizesAttribute = [];
  let defaultUrl = null;
  Object.entries(sizes).forEach(([mediaQuery, [width, height]]) => {
    const params =
      height > 0
        ? { width, height, fit: "crop", screenWidth: MAX_WIDTH }
        : { width, screenWidth: MAX_WIDTH };
    srcset[width] = optimizedImage(url, { ...params, devicePixelRatio: 1 });
    srcset[width * 2] = optimizedImage(url, { ...params, devicePixelRatio: 2 });
    if (mediaQuery === "default") {
      defaultUrl = srcset[width];
      sizesAttribute.push(`${width}px`);
    } else {
      sizesAttribute.push(`(${mediaQuery}) ${width}px`);
    }
  });

  return {
    srcSet: Object.entries(srcset)
      .map(([width, url]) => `${url} ${width}w`)
      .join(", "),
    sizes: sizesAttribute.join(", "),
    src: defaultUrl,
  };
}

export function imageHelper(images, folder) {
  return (image, mode, opts) => {
    if (typeof mode != "string") {
      opts = mode;
      mode = "url";
    }
    if (process.env.NODE_ENV === "development" && !images[image]) {
      throw new Error(
        `Image '${image}' not found in folder '${folder}'. Dev server needs restarting to refresh images. Avaliable images are ${JSON.stringify(
          Object.keys(images)
        )}`
      );
    }
    let url = images[image].url;
    url = optimizedImage(url, opts);
    if (mode === "url") {
      return url;
    } else if (mode === "css") {
      return { backgroundImage: `url('${url}')` };
    }
  };
}

export function customSeoMeta(content) {
  const meta = content?.seo?.meta || [];

  return meta.map(item => ({
    hid: item.name,
    [item.name_or_property]: item.name,
    content: item.content,
  }));
}
