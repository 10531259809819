import React from 'react';
import cx from "classnames";
import Link from 'next/link';
import PropTypes from 'prop-types';
import Actions from '@/components/cards/ArticleCard/Actions';
import ContentstackImage from '@/components/ContentstackImage';
import { Lock } from '@/icons';
import { articleType } from '@/types';
import { articleImage } from '@/utilities/articles/articleCard';
import { useTranslation } from "@separate/hooks/translation";
import { setAnalyticsUser } from "@separate/utilities/analytics";
import { formatLocalDateSimple } from '@separate/utilities/datetime';
import styles from './articleCardDesktop.module.scss';

const ArticleCardDesktop = ({ article, className, userIsPremium, isLoggedIn, isLoading, index, searchResultsCount, user }) => {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const isLocked = !userIsPremium && article.is_premium;
  const linkToArticle = isLocked ? '#/' : `/articles/${article.article_category}${article.url}`;
  const NEWEST_ARTICLE = 0;

  function handleCardClick() {
    setAnalyticsUser(user);

    if (!isLocked) {
      return;
    }
    setShowMessage(true);
  }

  function handleLogin() {
    setShowMessage(false);
    setShowLogin(true);
  }

  const premiumUserAndArticle = userIsPremium && article.is_premium;
  const loadingUserAndArticleIsPremium = isLoading && article.is_premium;

  return (
    <div className={className}>
      <Link href={linkToArticle}>
        <a
          className={cx(styles.link, loadingUserAndArticleIsPremium && styles.inactiveLink)}
          onClick={handleCardClick}
        >
          <div className={styles.cardImageWrapper}>
            <ContentstackImage
              src={articleImage(article)}
              width={700}
              className={cx(styles.cardImage, index === NEWEST_ARTICLE && !searchResultsCount && styles.fullCardImage)}
            />
            {loadingUserAndArticleIsPremium || isLocked ? (
              <div className={cx(styles.lock, "d-flex flex-column justify-content-center align-items-center")}>
                <Lock className="mb-1" />
                SEP+
              </div>
            ) : premiumUserAndArticle && (
              <div className={cx(styles.premiumUserAndArticle, "d-flex flex-column justify-content-center align-items-center")}>
                SEP+
              </div>
            )}
          </div>

          <div className={cx(styles.cardHeader, index === NEWEST_ARTICLE && !searchResultsCount && styles.fullCardHeader, 'mt-2')}>
            <span>{t(`articles.categories.${article.article_category}`)}</span>
            <small>{t("articles.published")}&nbsp;{formatLocalDateSimple(article?.publish_details?.time)}</small>
            <p>{article.title}</p>
            <div className={cx(styles.headerDivider, 'mt-1')} />
            <p className={styles.articleDescription}>{article?.article_description}</p>
          </div>
        </a>
      </Link>
      {isLocked && (
        <Actions
          showMessage={showMessage}
          showLogin={showLogin}
          dismissMessage={() => setShowMessage(false)}
          dismissLogin={() => setShowLogin(false)}
          handleLogin={handleLogin}
          isLoggedIn={isLoggedIn}
        />
      )}
    </div>
  );
};

export default ArticleCardDesktop;

ArticleCardDesktop.propTypes = {
  article: articleType,
  className: PropTypes.string,
  userIsPremium: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  index: PropTypes.number,
  searchResultsCount: PropTypes.number,
  user: PropTypes.object,
};
